<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle class="justify-space-between">
            <span>Редактирование топиков</span>
            <div>
              <VBtn
                class="mr-3"
                depressed
                color="primary"
                :to="{ name : Names.R_CONTENT_STORAGE_COURSE_EDIT, query : { ...$route.query } }"
              >
                <VIcon left>
                  fal fa-chevron-left
                </VIcon>

                Назад к курсу
              </VBtn>
              <VBtn
                depressed
                color="primary"
                :to="{ name : Names.R_CONTENT_STORAGE_COURSE_TOPICS_CREATE, query : { ...$route.query } }"
              >
                <VIcon left>
                  fal fa-plus
                </VIcon>

                Добавить топик
              </VBtn>
            </div>
          </VCardTitle>
          <VDivider />
          <VCardText>
            <CourseTopicsTable
              :topics="topics"
              @remove:content="removeTopicContent"
            />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import omit from 'lodash/omit';
import { queryParse } from '@front.backoffice/common';
import CourseTopicsTable from '../../../components/contentStorage/courses/CourseTopicsTable.vue';

export default {
  name: 'CourseTopicsEdit',
  components: { CourseTopicsTable },
  inject: ['Names'],
  data() {
    return {
      cachedCourse: {},
      topics: [],
    };
  },
  created() {
    if (!this.$route.query.labels) {
      this.$di.notify.snackError('Отсутствуют метки в адресе');
      return;
    }
    this.getCourse();
  },
  methods: {
    getCourse() {
      const { labels: routeLabels } = queryParse(this.$route.query.labels);

      const payload = {
        labels: routeLabels,
        pagination: {
          limit: 100,
        },
      };

      /**
       * TODO: разобраться совместно с бэкендом с этим бардаком
       * Note: Делаем первый запрос для получения topicKeys
       * Это понадобится для запроса всего контента
       */
      this.$di.api.ContentStorage
        .courseItemGet(payload)
        .then((response) => {
          payload.topics = response.topicKeys;
          payload.pagination = {
            ...response.pagintaion,
            limit: response.total,
          };

          /**
           * Note: Делаем второй запрос, чтобы забрать ВЕСЬ контент
           * потому что если бэкенду не прислать его обратно - он удалит весь контент
           */
          this.$di.api.ContentStorage
            .courseItemGet(payload)
            .then((fullCourse) => {
              this.topics = fullCourse.topics ?? [];
              this.cachedCourse = {
                ...fullCourse.course,
              };
            })
            .catch(this.$di.notify.errorHandler);
        })
        .catch(this.$di.notify.errorHandler);
    },
    removeTopicContent(topicKey, contentId) {
      const targetIndex = this.topics.findIndex((topic) => topic.key === topicKey);
      const target = { ...this.topics[targetIndex] };
      const start = this.topics.slice(0, targetIndex);
      const end = this.topics.slice(targetIndex + 1);

      target.items = target.items.filter((item) => item.id !== contentId);

      this.topics = [...start, target, ...end];
      this.updateCourse();
    },
    updateCourse() {
      // Note: для сохранения топиков требуется в items посылать id контента, а не сам контент
      const topicsWithContentId = this.topics.map((topic) => {
        // Note: В топике вместо набора контента может ничего не придти (это будет ключ all)
        if (!topic.items) {
          return topic;
        }

        return {
          ...topic,
          items: topic.items.map((item) => item.id),
        };
      });

      const payload = {
        ...omit(this.cachedCourse, ['id', 'topicKeys']),
        topics: topicsWithContentId,
      };

      this.$di.api.ContentStorage.courseItemEdit(payload).catch(this.$di.notify.errorHandler);
    },
  },
};
</script>

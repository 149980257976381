<template>
  <VDataTable
    :headers="headers"
    :items="topics"
    :expanded.sync="expanded"
    item-key="key"
    show-expand
    class=""
  >
    <template #item.actions="{ item }">
      <VBtn
        icon
        small
        color="green"
        :to="{
          name : Names.R_CONTENT_STORAGE_COURSE_TOPICS_CONTENT_EDIT,
          params : { topicKey : item.key },
          query : { ...$route.query }
        }"
      >
        <VIcon small>
          fal fa-plus
        </VIcon>
      </VBtn>
    </template>

    <template #expanded-item="{ item }">
      <VRow
        class="px-4"
        align="center"
      >
        <VCol cols="3">
          <span class="font-weight-bold">ID</span>
        </VCol>
        <VCol cols="5">
          <span class="font-weight-bold">Название</span>
        </VCol>
        <VCol cols="3">
          <span class="font-weight-bold">Тип контента</span>
        </VCol>
      </VRow>
      <VDivider />

      <div
        v-for="content in item.items"
        :key="content.id"
      >
        <VRow
          class="px-4"
          align="center"
        >
          <VCol cols="3">
            {{ content.id }}
          </VCol>
          <VCol cols="5">
            {{ content.content.title || 'Нет названия' }}
          </VCol>
          <VCol cols="3">
            {{ content.contentType }}
          </VCol>
          <VCol
            cols="1"
            align="end"
          >
            <VBtn
              icon
              small
              color="red"
              @click="$emit('remove:content', item.key, content.id)"
            >
              <VIcon small>
                fal fa-trash
              </VIcon>
            </VBtn>
            <VBtn
              icon
              color="warning"
              :to="{
                name : Names.R_CONTENT_STORAGE_CONTENT_EDIT,
                params : {
                  contentId : content.id
                }
              }"
            >
              <VIcon small>
                fal fa-edit
              </VIcon>
            </VBtn>
          </VCol>
        </VRow>
        <VDivider />
      </div>
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'CourseTopicsTable',
  inject: ['Names'],
  props: {
    topics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Ключ топика',
          sortable: false,
          value: 'key',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
      expanded: [],
    };
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.topics,"expanded":_vm.expanded,"item-key":"key","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{attrs:{"icon":"","small":"","color":"green","to":{
        name : _vm.Names.R_CONTENT_STORAGE_COURSE_TOPICS_CONTENT_EDIT,
        params : { topicKey : item.key },
        query : Object.assign({}, _vm.$route.query)
      }}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-plus ")])],1)]}},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
return [_c('VRow',{staticClass:"px-4",attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("ID")])]),_c('VCol',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Название")])]),_c('VCol',{attrs:{"cols":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Тип контента")])])],1),_c('VDivider'),_vm._l((item.items),function(content){return _c('div',{key:content.id},[_c('VRow',{staticClass:"px-4",attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(content.id)+" ")]),_c('VCol',{attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(content.content.title || 'Нет названия')+" ")]),_c('VCol',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(content.contentType)+" ")]),_c('VCol',{attrs:{"cols":"1","align":"end"}},[_c('VBtn',{attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.$emit('remove:content', item.key, content.id)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1),_c('VBtn',{attrs:{"icon":"","color":"warning","to":{
              name : _vm.Names.R_CONTENT_STORAGE_CONTENT_EDIT,
              params : {
                contentId : content.id
              }
            }}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)],1)],1),_c('VDivider')],1)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }